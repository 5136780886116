import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import bookCover from "src/images/books/double-roi/book_cover@2x.png"

export default function ContextualMessaging() {
  return (
    <Layout>
      <SEO
        title={"Doubling the ROI of Contextual Messaging"}
        description={
          "How any organization can use contextual messaging better - download the free ebook."
        }
      />
      <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 pb-24">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span class="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Essential Guide
              </span>
              <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span class="block text-gray-900">
                  Doubling the ROI of Contextual Messaging
                </span>
              </span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              A simple guide to increasing the return on investment of
              contextual messaging.
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Do you want to turbocharge engagement and conversions by showing,
              rather than telling, your customers what to do?
            </p>
            <p class="mt-4 text-lg text-gray-500">
              If so, we've got the product for you.
            </p>
            <p class="mt-4 text-lg text-gray-500">
              The weakness of in-app messaging solutions is that they're largely
              text based - and pushing a confused user further down the funnel
              with text alone is a tall order.
            </p>
            <p class="mt-4 text-lg text-gray-500 font-bold">
              That's why more and more businesses are turning to Nickelled to
              incorporate beautiful, full-page guides into their contextual
              messaging.
            </p>
            <div class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <p class="text-base font-medium text-gray-900">
                Enter your email and we'll send it right now:
              </p>
              <form
                name="contextualMessaging"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                method="POST"
                class="mt-3 sm:flex"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="contextualMessaging"
                />
                <label for="email" class="sr-only">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                  Send It
                </button>
              </form>
            </div>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div class="relative mx-auto w-full lg:max-w-md">
              <img class="w-full" src={bookCover} alt="" />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
